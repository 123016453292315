import { graphql } from 'gatsby'
import { Author } from './Author'

export default Author

export const query = graphql`
fragment AuthorFragment on ContentfulAuthor {
  firstName
  lastName
  photo {
    fixed(quality: 80, height: 60, width: 60) {
      ... GatsbyContentfulFixed_withWebp
    }
    ... FixedImageFragment
  }
}
`
