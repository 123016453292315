import React from 'react'
import { graphql } from 'gatsby'

import { SmallHero } from '../../components/Hero'
import { processSEO, processArticle } from '../../contentful'
import { ContactElement } from '../../enums'
import Layout from '../../layout/Layout'
import { BlogArticle } from './BlogArticle'

interface Props {
  data: {
    article: ArticleSource,
  },
  pageContext: {
    slug: string,
  },
}

const BlogArticleWrapper: React.FC<Props> = ({ data }) => {
  const article = data.article

  // Article will always exist, gatsby will display 404 if slug is not found during build
  return article ? (
    <Layout
      pageContext={{
        ...createPageContext(article),
        contactLinkOptions: { element: ContactElement.ContactSection },
      }}
    >
      <SmallHero img={article.primaryImage}/>
      <BlogArticle
        article={processArticle(article)}
      />
    </Layout>
  ) : <noscript/>
}

export default BlogArticleWrapper

const createPageContext = (a: ArticleSource): PageContext => ({
  id: a.slug,
  headline: a.headline,
  sections: [],
  subheadline: '',
  seo: processSEO(a.seo),
})

export const query = graphql`
fragment BlogArticleFragment on ContentfulBlogArticle {
  id
  headline
  slug
  publishedDt
  estimatedReadTimeMinutes
  listImage {
    fluid(quality: 80, maxWidth: 1100, maxHeight: 1100) {
      ... GatsbyContentfulFluid_withWebp
    }
    ... FluidImageFragment
  }
  tags
  lead {
    lead
  }
  firstPart {
    firstPart
  }
  secondPart {
    secondPart
  }
  thirdPart {
    thirdPart
  }
  primaryImage {
    fluid(quality: 80, maxWidth: 1000) {
      ... GatsbyContentfulFluid_withWebp
    }
    ... FluidImageFragment
  }
  secondaryImage {
    fluid(quality: 80, maxWidth: 900) {
      ... GatsbyContentfulFluid_withWebp
    }
    ... FluidImageFragment
  }
  tertiaryImage {
    fluid(quality: 80, maxWidth: 900) {
      ... GatsbyContentfulFluid_withWebp
    }
    ... FluidImageFragment
  }
  author {
    ...AuthorFragment
  }
  seo {
    ... SEOFragment
  }
}
query getData($slug: String){
  article: contentfulBlogArticle (slug: {eq: $slug}) {
    ... BlogArticleFragment
  }
}
`
