import React from 'react'
import Img from 'gatsby-image'

import { formatDate } from '../../utils'
import Card from '../Card'

import styles from './author.module.scss'

interface Props {
  author: Author
  publishedDt: Date
  className?: string
}

export const Author: React.FC<Props> = ({ author, publishedDt, className }: Props) => (
  <Card className={`${styles.author} ${styles.authorCard} ${className || ''}`} extraShadow>
    <Img
      fixed={author.photo.fixed}
      alt={author.photo.alt || photoAlt(author.firstName, author.lastName)}
      className={styles.authorImage}
    />
    <div>
      <div className={styles.authorInfo}>
        <h5 className='no-margin-btm primary-medium-grey'>Author</h5>
        <span className='paragraph pink'>{author.firstName} {author.lastName}</span>
      </div>
      <div className={styles.authorInfo}>
        <h5 className='no-margin-btm primary-medium-grey'>Published on</h5>
        <span className='paragraph primary-medium-grey'>{formatDate(publishedDt)}</span>
      </div>
    </div>
  </Card>
)

const photoAlt = (firstName: string, lastName: string) => `${firstName} ${lastName} photo`
