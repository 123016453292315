import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import {
  ArticleContent, ArticleHeadline, ArticleEstReadTime, ArticleTags,
} from '../../components/Article'
import ArticleComments from '../../components/ArticleComments'
import Author from '../../components/Author'
import Contact from '../../components/Contact'
import Share from '../../components/Share'
import { processArticle } from '../../contentful'
import { useHeaderContext } from '../../context'
import { CommentLoadMode } from '../../enums'
import { RelatedArticles } from './RelatedArticles'

import styles from '../../components/Article/scss/article.module.scss'

interface Props {
  article: Article
}

interface QueryProps {
  allArticles: {
    edges: Array<{
      node: ArticleSource,
    }>,
  },
}

export const BlogArticle: React.FC<Props> = ({ article }) => {
  const { isVisible } = useHeaderContext()
  const stickyClass = useMemo(() => (
    `${styles.sticky} ${isVisible ? styles.stickyWithNav : ''}`
  ), [isVisible])

  const allArticles = useStaticQuery<QueryProps>(articlesQuery).allArticles
  const indexOfCurrent = allArticles.edges.findIndex(
    item => item.node.slug === article.slug
  )
  // If there's only one related article, then prev == next
  const indexOfPrev = moduloHelper(indexOfCurrent - 1, allArticles.edges.length)
  const prev = allArticles.edges[indexOfPrev]
  const indexOfNext = moduloHelper(indexOfCurrent + 1, allArticles.edges.length)
  const next = allArticles.edges[indexOfNext]

  return (
    <>
      <article className={styles.article}>
        {/* TODO Extract ArticleInfo */}
        <div className='container grid-x'>
          <div className='cell small-12 medium-10 large-8 medium-offset-1 large-offset-2'>
            <div className={styles.info}>
              <ArticleTags tags={article.tags} isDetail/>
              <div className='show-for-medium'>
                <ArticleEstReadTime readTimeMin={article.estimatedReadTimeMinutes}/>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.articleInner}>
          <ArticleHeadline headline={article.headline}/>
          <div className='container grid-x'>
            <div className={styles.sideCard}>
              <div className={stickyClass}>
                <Author
                  author={article.author}
                  publishedDt={article.publishedDt}
                  className={styles.sideCardInner}
                />
              </div>
            </div>
            <div className={styles.body}>
              <section>
                <h5 className={`container ${styles.lead}`}>{article.lead}</h5>
              </section>
              <div className={styles.inlineAuthorContainer}>
                <div className='container'>
                  <Author author={article.author} publishedDt={article.publishedDt}/>
                </div>
              </div>
              <ArticleContent
                firstP={article.firstPart}
                secondP={article.secondPart}
                thirdP={article.thirdPart}
                secondaryImage={article.secondaryImage}
                tertiaryImage={article.tertiaryImage}
              />
              <div className={styles.shareContainer}>
                <Share title='Share this article'/>
              </div>
              <div className={styles.articleComments}>
                <ArticleComments id={`/blog/${article.slug}`} loadMode={CommentLoadMode.Scroll}/>
              </div>
            </div>
          </div>
        </div>
      </article>
      {allArticles.edges.length - 1 &&
        <RelatedArticles
          prev={processArticle(prev.node)}
          next={processArticle(next.node)}
        />}
      <Contact/>
    </>
  )
}

const moduloHelper = (a: number, b: number): number => ((a % b) + b) % b

const articlesQuery = graphql`
query {
  allArticles: allContentfulBlogArticle (
    filter: {pinkdroids: {eq: true}},
    sort: {fields: [publishedDt], order: DESC},
  ) {
    edges {
      node {
        ... BlogArticleFragment
      }
    }
  }
}
`
