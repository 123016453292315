import React from 'react'
import Hyvor from 'hyvor-talk-react'

import { CommentLoadMode } from '../../enums'

const ID = process.env.HYVOR_ID

interface Props {
  id?: string
  url?: string
  loadMode?: CommentLoadMode
}

export const ArticleComments: React.FC<Props> = ({ id, url, loadMode }) => (
  ID ? <Hyvor.Embed websiteId={parseInt(ID, 10)} id={id} url={url} loadMode={loadMode}/> : null
)
