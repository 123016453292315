import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { Arrow } from '../../components/icons'
import { Separator } from '../../components/Separator/Separator'
import { ArrowDirection } from '../../enums'
import Section from '../../layout/Section'

import styles from '../../components/Article/scss/article-related.module.scss'

type Props = {
  prev: Article,
  next: Article,
}

type ArticleShortcutProps = {
  direction?: string,
  relatedArticle: Article,
}

export const RelatedArticles: React.FC<Props> = ({
  prev,
  next,
}) => {
  return (
    <Section
      topPadding='large'
      bottomPadding='large'
      grid
      className={styles.relatedBlogArticles}
      containerClassName={styles.relatedBlogArticles}
    >
      <div className={styles.relatedArticlesContainer}>
        <div className={styles.relatedArticlesHeadline}>
          <h2 className='dark-bg'>{'More articles'}</h2>
          <img src='/images/illustrations/star-cluster2.svg' alt='Background stars'/>
        </div>
        <div className={styles.relatedArticlesShortcut}>
          <ArticleShortcut direction='left' relatedArticle={prev}/>
          <Separator className={styles.separatorArticle}/>
          <ArticleShortcut direction='right' relatedArticle={next}/>
        </div>
      </div>
    </Section>
  )
}

const ArticleShortcut: React.FC<ArticleShortcutProps> =
({ direction = 'right', relatedArticle }) => {
  return (
    <div className={styles.articleShortcut}>
      <Link to={`/blog/${relatedArticle.slug}/`}>
        <div className={styles.shortcutInfoContainer}>
          <div className={styles.shortcutInfo}>
            <Arrow
              direction={direction === 'left' ? ArrowDirection.Left : ArrowDirection.Right}
              className={styles.arrow}
            />
            <h5 className={styles.navDescription}>
              {getPrevOrNext(direction)}
            </h5>
            <h3 className={styles.articleHeadline}>{relatedArticle.headline}</h3>
          </div>
        </div>
        <Img
          className={styles.articleImage}
          fluid={relatedArticle.listImage.fluid}
          alt={relatedArticle.listImage.alt}
          title={relatedArticle.headline}
        >
        </Img>
      </Link>
    </div>
  )
}

const getPrevOrNext = (direction: string): string =>
  direction === 'left' ? 'PREVIOUS ARTICLE' : 'NEXT ARTICLE'
